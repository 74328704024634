import { FormInstance } from 'antd'
import { SortOrder } from 'antd/lib/table/interface'
import { AxiosError, AxiosResponse } from 'axios'
import dayjs, { UnitType } from 'dayjs'
import customPareFormat from 'dayjs/plugin/customParseFormat'
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'hooks/usePaging'
import { Item } from 'interfaces/order'
import { PurchaseOrder } from 'interfaces/purchaseOrder'
import _, { get, isArray, isBoolean, isObject, round } from 'lodash'
import getConfig from 'next/config'

import { IErrorMessage } from '../interfaces'
import { errorMessage } from './errorMessage'
import { IS_DATE_RANGE, IS_LAST_30_DAYS } from './productPreOrder'

dayjs.extend(customPareFormat)
export interface IValuesOrderItem {
  discVigo: number
  additionalDiscount: number
  taxableAmount: number
  cgstAmount: number
  sgstAmount: number
  cessAmount: number
  igstAmount: number
  totalTax: number
  totalSubsidy: number
  netAmount: number
}

const {
  publicRuntimeConfig: { timeZone },
} = getConfig()

export interface IErrorResponse {
  code: string
  message: string
  errors?: ValidationError[]
}

export interface ValidationError {
  field: string
  code: string
  argument: string
  message: string
}

export const defaultStringifyOption = {
  skipNulls: true,
  encode: false,
  indices: false,
}

export const getStaticUrl = (filename: string) => {
  return `${process.env.cdnPrefix}/static/${filename}`
}

export const getResponseData = <T>(response: AxiosResponse<{ data: T }>): T => {
  return response?.data?.data
}

export const getResponseErrorMessage = (
  err: AxiosError,
  isAdminPortal?: boolean
): string | ValidationError[] => {
  if (!err.response) {
    return err.message
  }
  const data = err.response.data
  if (isAdminPortal && data.message) {
    return data.message
  }
  const payload: IErrorResponse = data.errors
  if (!payload) {
    return err.response.statusText
  }
  try {
    const validationErrors = payload.errors
    return validationErrors
      ? validationErrors
      : errorMessage[`${payload.code}`] ||
          payload.message ||
          data.message ||
          err.response.statusText
  } catch (e) {
    return err.response.statusText
  }
}

export const TAX_AMOUNT_MAPPING = {
  cgstPercent: 'centralGstAmount',
  sgstPercent: 'stateGstAmount',
  cessPercent: 'cessAmount',
  igstPercent: 'igstAmount',
}
export const setValidationError = (
  form: FormInstance,
  errs: ValidationError[],
  customMessage?: IErrorMessage
): void => {
  const vErrs = errs.map((err) => {
    let message = null
    const customCode = _.camelCase(err.field + ' ' + err.code)
    if (customMessage && customMessage[customCode]) {
      message = customMessage[customCode]
    }

    message = message || errorMessage[err.code] || err.message || err.code
    return {
      name: err.field.split('.'),
      errors: [message],
    }
  })
  form.setFields(vErrs)
}

export const getValidationError = (
  errs: ValidationError[],
  customMessage?: IErrorMessage
): string => {
  if (errs.length > 0) {
    let message = null
    const err = errs[0]
    const customCode = _.camelCase(err.field + ' ' + err.code)
    if (customMessage && customMessage[customCode]) {
      message = customMessage[customCode]
    }
    return message || errorMessage[err.code] || err.message || err.code
  }
  return ''
}

export interface ISort {
  orderBy: string
}

export const getFiltersSortOrder = (
  filters: ISort,
  fieldName: string
): SortOrder => {
  if (filters.orderBy !== undefined && filters.orderBy.length > 0) {
    const words = filters.orderBy.split(' ')
    if (words.length > 1) {
      return words[0] !== fieldName
        ? null
        : words[1] === 'DESC'
        ? 'descend'
        : 'ascend'
    }
  }
  return null
}

export const getNewSortInfo = (fieldName = '', typeSort: string): ISort => {
  if (typeSort && fieldName) {
    switch (typeSort) {
      case 'ascend':
        return {
          orderBy: fieldName + ' asc',
        }
      case 'descend':
        return {
          orderBy: fieldName + ' desc',
        }
      default:
        return {
          orderBy: fieldName + ' desc',
        }
    }
  }
}

export const formatMoneyIndia = (amount: number) => {
  const money = parseFloat(amount?.toFixed(2)) || 0
  return new Intl.NumberFormat('en-In', { minimumFractionDigits: 2 }).format(
    money
  )
}
export const roundingNumberIndia = (number: number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100 || 0
}

export const roundingNumber = (number: number) => round(number, isIndia ? 2 : 3)

export const formatMoney = (amount: number, minFraction = true) => {
  if (amount == null) return ''
  const options = {
    style: 'decimal',
    minimumFractionDigits: null,
  }
  if (minFraction) {
    options.minimumFractionDigits = isIndia ? 2 : 3
  }
  if (isIndia) {
    return new Intl.NumberFormat('en-In', options).format(amount)
  } else {
    return new Intl.NumberFormat('en-US', options).format(amount)
  }
}
export const formatMoneyInputIndia = (value: number) => {
  {
    const options = {
      style: 'decimal',
      maximumFractionDigits: 10,
    }
    return Intl.NumberFormat('en-IN', options).format(value)
  }
}

export const formatMoneyLimitDecimals = (value: string, limit = 2) => {
  if (value) {
    // eslint-disable-next-line no-useless-escape
    const regex = new RegExp(`^\\d+(?:\\.\\d{0,${limit}})?`)

    const preventTwoDecimals = value.toString().match(regex)
    const valueFormat = `${preventTwoDecimals}`?.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )

    return valueFormat || '1'
  }
  return value?.toString() || '1'
}

export const defaultFilters = {
  page: DEFAULT_PAGE,
  perPage: DEFAULT_PAGE_SIZE,
}

export const inputNumberRegex = /^[+-]?\d*\.?\d+(?:[Ee][+-]?\d+)?$/

export const phoneNumberRegex = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/

export const formatNumber = (value: number) =>
  new Intl.NumberFormat().format(value)

export const safeNumberParse = (value, fallback: number) => {
  try {
    const nextValue = parseInt(value, 10)
    if (isNaN(nextValue)) {
      throw Error('')
    }
    return nextValue
  } catch (err) {
    return fallback
  }
}

export const safeObjectParse = (value, fallback) => {
  try {
    const nextValue = JSON.parse(value)
    if (isObject(nextValue)) {
      return nextValue
    }
  } catch (err) {
    return fallback
  }
}

export const eliminateProperty = (obj) =>
  Object.entries(obj).reduce(
    (prev, [k, v]) => (v || isBoolean(v) ? { ...prev, [k]: v } : prev),
    {}
  )

export const sortDataByKey = (
  data,
  keyArr?: string | null,
  keyValue?: string | null,
  sortByASC = true
) => {
  if (typeof data === 'object' && data !== null && !Array.isArray(data)) {
    if (keyArr && keyValue) {
      if (sortByASC) {
        data[keyArr].sort((a, b) => {
          return a[keyValue] - b[keyValue]
        })
        return data
      } else {
        data[keyArr].sort((a, b) => {
          return b[keyValue] - a[keyValue]
        })
        return data
      }
    } else return data
  }

  if (Array.isArray(data) && data.length > 0) {
    if (keyValue) {
      if (sortByASC) {
        data.sort((a, b) => {
          return a[keyValue] - b[keyValue]
        })
        return data
      } else {
        data.sort((a, b) => {
          return b[keyValue] - a[keyValue]
        })
        return data
      }
    } else return data
  }
}

export const uniqueArray = (arr) => {
  let newArr = []
  newArr = arr.filter(function (item) {
    return newArr.includes(item) ? '' : newArr.push(item)
  })
  return newArr
}

export const isIndia = timeZone === 'Asia/Kolkata'

export const sortByDate = (arr, orderBy = 'asc', property = 'createdAt') =>
  arr?.sort((a, b) =>
    orderBy === 'asc'
      ? (dayjs(a[property]) as any) - (dayjs(b[property]) as any)
      : (dayjs(b[property]) as any) - (dayjs(a[property]) as any)
  )

export const linkUserGuide =
  'https://docs.google.com/document/d/1MMhjYaZQxsLxOh4Gy8egEEYopbgPUk71XZspPg-5Zug/edit#heading=h.kasbj9ka6esz'

export const convertToArray = (value: any, isAcceptFalsy?: boolean) => {
  if (isAcceptFalsy && !Array.isArray(value)) return [value]
  if (value && !Array.isArray(value)) return [value]
  return value
}

export const sortDataByName = (data: any, property: string) => {
  return data?.sort((a, b) =>
    get(a, property)?.name.localeCompare(get(b, property)?.name)
  )
}

export const handleEncode = (data: string | string[]) =>
  Array.isArray(data)
    ? data.map((item) => item && encodeURIComponent(item))
    : data && encodeURIComponent(data as string)

export const chunkBlock = (data, numberPerBlock) => {
  const blocks = []
  while (data?.length > 0) {
    const chunked = data.splice(0, numberPerBlock)
    blocks.push(...chunked)
  }
  return blocks
}

export const PromisePool = async (handler, data, concurency) => {
  const iterator = data?.entries()
  const workers = new Array(concurency).fill(iterator).map(async (iterator) => {
    for (const [index, item] of iterator) {
      await handler(item, index)
    }
  })
  await Promise.all(workers)
}

export const getDateByFilters = (
  filters: any,
  fromDateKey: string,
  toDateKey: string
) => {
  const currentDate = dayjs().endOf('day').toISOString()

  const last7Days = dayjs(currentDate)
    .subtract(7, 'day')
    .startOf('day')
    .toISOString()

  const last30Days = dayjs(currentDate)
    .subtract(30, 'day')
    .startOf('day')
    .toISOString()
  if (filters?.filterDate)
    switch (filters?.filterDate) {
      case IS_LAST_30_DAYS:
        return { [fromDateKey]: last30Days, [toDateKey]: currentDate }
      case IS_DATE_RANGE:
        return {
          [fromDateKey]: filters?.fromDate,
          [toDateKey]: filters?.toDate,
        }
      default:
        return { [fromDateKey]: last7Days, [toDateKey]: currentDate }
    }

  return
}

export const isDate = (value) => dayjs(value, 'YYYY-MM-DD', true).isValid()

export const getTaxAmountOrderItem = (
  orderItem: Item,
  quantity: number,
  taxPercentage
) => {
  const ratioQuantity = quantity / orderItem?.quantity || 0
  const keyTaxMount = TAX_AMOUNT_MAPPING?.[taxPercentage]
  // Field tax amount from BE calculated from unit price and promotionDiscount not include additionalDiscount, FE calculated subtract additionalDiscount in tax amount
  return ratioQuantity * orderItem?.[keyTaxMount] || 0
}
export const valuesOrderItem = (orderItem: Item, quantity: number) => {
  const ratioQuantity = quantity / orderItem?.quantity || 0
  const discVigo = orderItem?.promotionDiscount * ratioQuantity || 0
  const additionalDiscount =
    orderItem?.additionalCartDiscount * ratioQuantity || 0
  //tax type
  const cgstAmount = getTaxAmountOrderItem(orderItem, quantity, 'cgstPercent')

  const sgstAmount = getTaxAmountOrderItem(orderItem, quantity, 'sgstPercent')

  const cessAmount = getTaxAmountOrderItem(orderItem, quantity, 'cessPercent')
  const igstAmount = getTaxAmountOrderItem(orderItem, quantity, 'igstPercent')
  //Taxable Amount
  const taxableAmount =
    orderItem?.unitPrice * quantity - discVigo - additionalDiscount || 0

  const totalTax =
    round(cgstAmount, 2) +
    round(sgstAmount, 2) +
    round(cessAmount, 2) +
    round(igstAmount, 2)
  //discountAgent
  const discountAgent = orderItem?.agentPriceSubsidy * ratioQuantity || 0
  const distributorPriceSubsidy =
    orderItem?.distributorPriceSubsidy * ratioQuantity || 0
  const totalSubsidy = discountAgent + distributorPriceSubsidy || 0
  const netAmount = taxableAmount + totalTax - totalSubsidy || 0

  return {
    discVigo,
    additionalDiscount,
    taxableAmount,
    cgstAmount,
    sgstAmount,
    cessAmount,
    igstAmount,
    totalTax,
    totalSubsidy,
    netAmount,
  }
}

export const camelToFlat = (camel) => {
  const string: string = camel.replace(/([a-z])([A-Z])/g, '$1 $2')
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const dataQueryMappingForCascader = (dataQuery: any) => {
  const dataMapping = isArray(dataQuery)
    ? dataQuery?.map((i) => [i])
    : [[dataQuery]]
  return dataMapping
}

export const extractEmail = (text: string): string | null => {
  const emailRegex = /[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,}/
  const match = text.match(emailRegex)
  return match ? match[0] : null
}
export const convertToUppercaseNoAccent = (text) => {
  const upperText = text.toUpperCase()

  const noAccentText = upperText
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

  return noAccentText
}

export const checkExistData = (data) => {
  const existData = {}
  return data?.reduce((prev, curr) => {
    if (existData[curr?.id]) {
      return prev
    }
    existData[curr?.id] = true
    return [...prev, curr]
  }, [])
}

//This function is used to calculate total selling price
export const getTotalPrice = (data: PurchaseOrder) => {
  return data.items?.reduce((prev, curr) => {
    const adjustQty = curr?.quantity || 0
    const price = curr?.paymentPrice || 0
    return prev + adjustQty * price
  }, 0)
}

export const validateDateRange = (
  from,
  to,
  keyCheck: UnitType = 'month',
  valueCheck = 5
) => {
  if (from && to) {
    const fromDate = dayjs(from).startOf('d')
    const toDate = dayjs(to).endOf('d')

    return toDate.diff(fromDate, keyCheck) > valueCheck
  }
}
